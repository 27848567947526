import { operator } from '@rs/core/application';
import { arrayToObject, objectToArray } from '@rs/core/utils/dataUtils';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { createReducer } from '../../Lib/reduxUtils';
import {
    LOAD_DRIVERS,
    FILTER_UPDATED,
    LOAD_CORNER_LEAGUE,
    SORT_TABLE,
    SET_FILTERS_WITH_URL_PARAMS,
    SET_SPINNER_STATE,
    FETCH_MINESITE_AREAS_TREE_SUCCESS,
    FETCH_CONDITIONAL_LIMIT_EVENTS,
} from '../Actions';
import { FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS } from '../../App/Actions';
import { SELECT_STRING_DELIMITER } from '../../Components/Select';
import {
    getFilterOptionsFromArray,
    getMissingFilterResults,
    operatorOptionsToReactSelectFormat,
} from '../../Utils/Filters/filters';
import {
    filterOptionsToReactSelectFormat,
    filterShiftOptionsToReactSelectFormat,
} from '../../Utils/Filters/filterOptionsToReactSelectFormat';
import {
    getFilteringConfig,
    getOperatorDisplayNameType,
    getTopLevelWhereOnMinesiteIdDepth,
} from '../../App/Selectors';
import { compareString } from '../../Lib/sortingUtils';
import filterOutTopLevelWOMIDs from '../../Modules/minesiteAreasTree/utils/filterOutTopLevelWomids';

export const getAllDrivers = (state: any) =>
    objectToArray(state.driversByUniqueDriverId);

export const getFilterOptions = (state: any, rootState: any) => {
    const filters = getFilters(state);
    const driverList = getAllDrivers(state);
    const filterConfig = getFilteringConfig(rootState);
    const minesiteAreas = filterOutTopLevelWOMIDs(
        state.minesiteAreasTree,
        getTopLevelWhereOnMinesiteIdDepth(rootState),
    );
    const operatorDisplayNameType: string = getOperatorDisplayNameType(
        rootState,
    );

    if (
        !(
            operatorDisplayNameType === 'Name' ||
            operatorDisplayNameType === 'Id' ||
            operatorDisplayNameType === 'Blurred' ||
            operatorDisplayNameType === 'All'
        )
    ) {
        throw new Error('Unknown operator display name type');
    }

    const driverSelector = (d: any) => d.Worker;
    const shiftSelector = (d: any) => d.Shift;
    const crewSelector = (d: any) => d.Crew;

    const driverOptions = getFilterOptionsFromArray(
        driverList,
        [
            [shiftSelector, filters.Shift],
            [crewSelector, filters.Crew],
        ],
        driverSelector,
    );

    const shiftOptions = getFilterOptionsFromArray(
        driverList,
        [
            [driverSelector, filters.Worker],
            [crewSelector, filters.Crew],
        ],
        shiftSelector,
    );

    const crewOptions = getFilterOptionsFromArray(
        driverList,
        [
            [shiftSelector, filters.Shift],
            [driverSelector, filters.Worker],
        ],
        crewSelector,
    );

    const shifts = shiftUtils.getAllShiftShortNamesForDate(filters.Date);

    return {
        drivers: operatorOptionsToReactSelectFormat(
            getMissingFilterResults(filters.Worker, driverOptions),
            operator.displayName.OperatorDisplayFormats[
                operatorDisplayNameType
            ],
        ),
        crews: filterOptionsToReactSelectFormat(
            getMissingFilterResults(filters.Crew, crewOptions),
        ),
        shifts: filterShiftOptionsToReactSelectFormat(
            shifts.rawShiftsInDay,
            getMissingFilterResults(filters.Shift, shiftOptions),
        ),
        activities: filterConfig.filterOptions.activities
            .slice()
            .sort((a: any, b: any) => compareString(b.label, a.label)),
        materials: filterConfig.filterOptions.materialTypes
            .slice()
            .sort((a: any, b: any) => compareString(b.label, a.label)),
        areas: minesiteAreas.map((row: any) => ({
            label: row.Name,
            value: row.WhereOnMinesiteId,
        })),
    };
};

const getFilters = (state: any) => state.filters;
interface GetDefinedFiltersOutput {
    Shift?: any;
    UniqueDriverId?: any;
    OperationalCrew?: any;
    Crew?: any;
    Material?: any;
    Womid?: any;
    Activity?: any;
    shiftIdsAllShifts?: any;
    shiftIdsSelectedShifts?: any;
}

const fetchPrivateSiteConfigurationSuccess = (state: any) => {
    const defaultDate = shiftUtils.newDefaultFilterDate();
    return {
        ...state,
        filters: {
            ...state.filters,
            Date: defaultDate,
        },
    };
};

const getDefinedFilters = (state: any): GetDefinedFiltersOutput => {
    const filters = sliceSelectors.getFilters(state);

    const params: GetDefinedFiltersOutput = {};
    if (filters.Shift.length > 0) {
        params.Shift = filters.Shift;
    }
    if (filters.Worker.length > 0) {
        const workersAsArray =
            filters.Worker.split(SELECT_STRING_DELIMITER) || [];
        params.UniqueDriverId = operator
            .operatorNamesToUniqueId(workersAsArray)
            .join(SELECT_STRING_DELIMITER);
    }
    if (filters.Crew.length > 0) {
        params.Crew = filters.Crew;
    }
    if (filters.Material.length > 0) {
        params.Material = filters.Material;
    }
    if (filters.Area.length > 0) {
        params.Womid = filters.Area;
    }
    if (filters.Activity.length > 0) {
        params.Activity = filters.Activity;
    }

    const shiftIdsAllShifts = shiftUtils.generateShiftIdRange(
        filters.Date,
        filters.EndDate,
    );
    const shiftIdsSelectedShifts = shiftUtils.generateShiftIdRange(
        filters.Date,
        filters.EndDate,
        filters.Shift.split(SELECT_STRING_DELIMITER),
    );

    params.shiftIdsAllShifts = shiftUtils.getAllFirstLastFromArray(
        shiftIdsAllShifts,
    );
    params.shiftIdsSelectedShifts = shiftUtils.getAllFirstLastFromArray(
        shiftIdsSelectedShifts,
    );

    return params;
};

const getSpinnerState = (state: any) => state.spinner;
const getCornerLeague = (state: any) => state.cornerLeague;
const getCornerLeagueTotal = (state: any) => state.cornerLeagueTotal;
const getCornerLeagueWeightings = (state: any) => state.cornerLeagueWeightings;
const getCornerLeagueOrder = (state: any) => state.cornerLeagueOrder;
const getCornerLeaguePredicate = (state: any) => state.cornerLeaguePredicate;
const getGearLockdown = (state: any) => state.gearLockdown;

export const sliceSelectors = {
    getFilters,
    getAllDrivers,
    getFilterOptions,
    getDefinedFilters,
    getCornerLeague,
    getCornerLeagueTotal,
    getCornerLeagueWeightings,
    getCornerLeagueOrder,
    getCornerLeaguePredicate,
    getSpinnerState,
    getGearLockdown,
};

// //////////////////////////////////////////////////////////////////////
//  REDUCERS
// //////////////////////////////////////////////////////////////////////

export const initialState = {
    driversByUniqueDriverId: {},
    cornerEvents: [],
    cornerLeague: [],
    cornerLeagueTotal: {},
    cornerLeagueWeightings: {},
    cornerLeagueOrder: 'Driver',
    cornerLeaguePredicate: false,
    filters: {
        Date: undefined,
        EndDate: undefined,
        Shift: '',
        Crew: '',
        Worker: '',
        rangeSelected: false,
        Material: '',
        Area: '',
        Activity: '',
    },
    spinner: {
        isActive: false,
        message: '',
        faIcon: undefined,
    },
    minesiteAreasTree: {},
    gearLockdown: [],
};

export function setDrivers(state: any, driversArray = []) {
    const driversByUniqueDriverId = arrayToObject(
        driversArray,
        'UniqueDriverId',
    );
    return {
        ...state,
        driversByUniqueDriverId,
    };
}

export function filterUpdated(state: any, payload: any) {
    const { filterName, filterValue: originalFilterValue } = payload;
    const filterValue = originalFilterValue === null ? '' : originalFilterValue;
    const newState = {
        ...state,
        filters: {
            ...state.filters,
            [filterName]: filterValue,
        },
    };
    // Handle toggling rangeSelected
    if (filterName === 'rangeSelected') {
        if (newState.filters.rangeSelected) {
            newState.filters.EndDate = newState.filters.Date;
        } else {
            newState.filters.EndDate = '';
        }
    }
    // Handle setting the EndDate to the same as Date if EndDate is before start Date
    if (filterName === 'Date') {
        const endDate = shiftUtils.createMomentInSiteTime(
            newState.filters.EndDate,
            shiftUtils.DATE_FORMAT__DISPLAY,
        );
        const startDate = shiftUtils.createMomentInSiteTime(
            newState.filters.Date,
            shiftUtils.DATE_FORMAT__DISPLAY,
        );
        if (newState.filters.rangeSelected && endDate.isBefore(startDate)) {
            newState.filters.EndDate = newState.filters.Date;
        }
    }
    return newState;
}

export function loadCornerLeague(state: any, payload: any) {
    const { CornerLeague, Total, Weightings } = payload;
    const newState = {
        ...state,
        cornerLeague: CornerLeague || [],
        cornerLeagueTotal: Total || {},
        cornerLeagueWeightings: Weightings || {},
    };
    return newState;
}

export function sortTable(state: any, { sortingKey }: any) {
    const order = state.cornerLeagueOrder;
    const predicate = state.cornerLeaguePredicate;

    const newState = {
        ...state,
        cornerLeagueOrder: sortingKey,
        cornerLeaguePredicate: order === sortingKey ? !predicate : predicate,
    };
    return newState;
}

export function setFiltersWithUrlParams(state: any, filtersFromUrl: any) {
    return {
        ...state,
        filters: {
            ...state.filters,
            ...filtersFromUrl,
        },
    };
}

// TODO It probably would make more sense to have this as it's own top level reducer and share it between pages
export function setSpinnerState(state: any, spinner: any) {
    const { isActive, message, faIcon } = spinner;
    return {
        ...state,
        spinner: {
            isActive,
            message,
            faIcon,
        },
    };
}

export function fetchMinesiteAreasTreeSuccess(
    state: any,
    minesiteAreasTree = {},
) {
    return {
        ...state,
        minesiteAreasTree,
    };
}

export function fetchConditionalLimitEevents(state: any, gearLockdown: any) {
    return {
        ...state,
        gearLockdown,
    };
}

export const reducer = createReducer(initialState, {
    [FILTER_UPDATED]: filterUpdated,
    [LOAD_DRIVERS]: setDrivers,
    [LOAD_CORNER_LEAGUE]: loadCornerLeague,
    [SORT_TABLE]: sortTable,
    [SET_FILTERS_WITH_URL_PARAMS]: setFiltersWithUrlParams,
    [SET_SPINNER_STATE]: setSpinnerState,
    [FETCH_MINESITE_AREAS_TREE_SUCCESS]: fetchMinesiteAreasTreeSuccess,
    [FETCH_CONDITIONAL_LIMIT_EVENTS]: fetchConditionalLimitEevents,
    [FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS]: fetchPrivateSiteConfigurationSuccess,
});
