// Copied from main website code

export default function generateApiUrl(clientPort = 3001) {
    // assume it is running in the kubernetes cluster
    const prMaxMineURLParts = document.domain.match(/.*\.pr\..*.max-mine.com$/);
    if (prMaxMineURLParts) {
        let hostWithPrIdRemoved = window.location.host.replace(
            /(.*)\.pr/,
            'pr',
        );
        return `${window.location.protocol}//api.${hostWithPrIdRemoved}`;
    }

    const maxMineURLPartsWithReporting = document.domain.match(
        /.*\.reporting(-dev)*\.max-mine\.com$/,
    );
    if (maxMineURLPartsWithReporting) {
        return `${window.location.protocol}//api.${window.location.host}`;
    }

    const maxMineURLPartsStaging = document.domain.match(
        /.*\.staging[0-9]+\.max-mine\.com$/,
    );
    if (maxMineURLPartsStaging) {
        // if using staging, just redirect straight to staging
        return window.location.origin;
    }

    const maxMineURLPartsWithFrontend = document.domain.match(
        /.*frontend\..*max-mine\.com$/,
    );
    if (maxMineURLPartsWithFrontend) {
        // if hitting a frontend server directly to get the javascript
        // then return the corresponding backend reverse proxy.
        let hostToBackend = window.location.host.replace(
            /frontend\./,
            'backend.',
        );

        // replace any pr frontend with a single backend
        if (hostToBackend.match(/^.*?-pr-[^.]+\./)) {
            hostToBackend = hostToBackend.replace(/^.*?-pr-[^.]+\./, `pr.`);
        }

        // return the backend
        return `${window.location.protocol}//${hostToBackend}`;
    }

    const maxMineURLParts = document.domain.match(
        /^([^.]*)\.([^.]*)\.max-mine\.com$/,
    );
    if (maxMineURLParts) {
        // production urls of the form <site>.<company>.max-mine.com
        // follow the backend for frontend pattern
        // make sure everything goes through the reporting-backend proxy
        let hostWithReportingAdded = window.location.host.replace(
            'max-mine.com',
            'reporting.max-mine.com',
        );
        return `${window.location.protocol}//api.${hostWithReportingAdded}`;
    }

    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment) {
        return `http://${document.domain}:${clientPort}/devapi`;
    }
    switch (document.domain) {
        case 'reports.befa.com.au':
            return 'api.befa.com.au';
        default:
            return `http://${document.domain}:3000`;
    }
}
