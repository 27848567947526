import React from 'react';
import { Row, Col } from '@rs/core/falcon';
import { Select } from '../../Components/Select';
import { DatepickerInputWithSiteConfig } from '../../Components/Datepicker';
import { TypeLoaderLeagueFilterOptions, TypeFilters } from '../types';

interface PrintLeagueFiltersProps {
    filters: TypeFilters;
}

const PrintLeagueFilter = ({ filters }: PrintLeagueFiltersProps) => (
    <Row className="whenPrintingShow">
        <Col span={24} style={{ paddingTop: 5 }}>
            <span>{`Date Start: ${filters.Date}`}</span>
        </Col>
        <Col span={24}>
            <span>{filters.EndDate ? `Date End: ${filters.EndDate}` : ''}</span>
        </Col>
        <Col span={24}>
            <span>{filters.Loader ? `Loader: ${filters.Loader}` : ''}</span>
        </Col>
        <Col span={24}>
            <span>
                {filters.Operator ? `Operator: ${filters.Operator}` : ''}
            </span>
        </Col>
        <Col span={24}>
            <span>{filters.Shift ? `Shift: ${filters.Shift}` : ''}</span>
        </Col>
    </Row>
);

interface LoaderLeagueFiltersProps {
    filters: TypeFilters;
    filterOptions: TypeLoaderLeagueFilterOptions;
    onFilterChange: Function;
}

const LoaderLeagueFilters = ({
    onFilterChange,
    filterOptions,
    filters,
}: LoaderLeagueFiltersProps) => {
    const handleFilterChangeShift = onFilterChange.bind(null, 'Shift');
    const handleFilterChangeOperator = onFilterChange.bind(null, 'Operator');
    const handleFilterChangeLoader = onFilterChange.bind(null, 'Loader');

    return (
        <Row>
            <PrintLeagueFilter filters={filters} />
            <Col span={24} className="whenPrintingHide">
                <Row>
                    <Col xs={24} lg={12}>
                        <DatepickerInputWithSiteConfig
                            startDate={filters.Date}
                            endDate={filters.EndDate}
                            onFilterChange={onFilterChange}
                            label={'Date'}
                            allowModeSwitch={true}
                            rangeSelected={filters.rangeSelected}
                        />
                    </Col>
                    <Col xs={24} lg={12}>
                        <label htmlFor="filterShift">Shift</label>
                        <Select
                            id="filterShift"
                            name="Shift"
                            value={filters.Shift}
                            options={filterOptions.shifts}
                            onChange={handleFilterChangeShift}
                            onRemove={handleFilterChangeShift}
                            simpleValue={true}
                            multi={true}
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={24} className={'whenPrintingHide'}>
                <Row>
                    <Col xs={24} lg={12}>
                        <label htmlFor="filterOperator">Operator</label>
                        <Select
                            id="filterOperator"
                            name="Operator"
                            value={filters.Operator}
                            options={filterOptions.operators}
                            onChange={handleFilterChangeOperator}
                            onRemove={handleFilterChangeOperator}
                            simpleValue={true}
                            multi={true}
                        />
                    </Col>
                    <Col xs={24} lg={12}>
                        <label htmlFor="filterLoaderUnitType">Load Unit</label>
                        <Select
                            id="filterLoaderUnitType"
                            name="Loader"
                            value={filters.Loader}
                            options={filterOptions.loaders}
                            onChange={handleFilterChangeLoader}
                            onRemove={handleFilterChangeLoader}
                            simpleValue={true}
                            multi={true}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default LoaderLeagueFilters;
