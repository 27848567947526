import errorLogging from '@rs/core/utils/errorLogging';
import merge from 'lodash/merge';
import { REDUCER_KEY } from '../Reducers';
import { getNavbarTabs } from '../../Auth/Selectors/features';

export const isAppInitialized = (state) => {
    const initState = state[REDUCER_KEY].init;
    return initState.hasLoaded;
};

export const getPrivateSiteConfigLoadStatus = (state) => {
    return state[REDUCER_KEY].privateSiteConfiguration.init;
};

export const getAppInitializedWithError = (state) =>
    state[REDUCER_KEY].init.error;

export const getSiteEnabledFeaturesByIdForState = (state) => {
    const featureToggles =
        state[REDUCER_KEY].publicSiteConfiguration.config.featureToggles || {};
    if (featureToggles['undefined'] === false) {
        errorLogging.logException(
            new Error(
                'There is an unknown feature mapped which set as false, it may result in some features are disabled. Possibly there is a feature in the site_configuration set up but missing in feature/index.ts file',
            ),
        );
    }
    delete featureToggles['undefined'];
    return featureToggles || {};
};

/**
 * Checks if the feature on a page is enabled.
 * @param featureId
 * @return {boolean}
 */
// Todo rewrite with createSelector
export const isFeatureEnabled = (state, featureId) => {
    const appInitialized = isAppInitialized(state);
    const featureToggles = getSiteEnabledFeaturesByIdForState(state);

    if (!appInitialized || !Object.keys(featureToggles).length) return false;
    const feature = featureToggles[featureId];
    if (feature === undefined) {
        errorLogging.logException(
            new Error(`featureId not found, ${featureId}`),
        );
        return false;
    }
    return feature;
};

// Todo rewrite with createSelector
export const getMinesiteId = (state) => {
    const interfaceSettings =
        state[REDUCER_KEY].publicSiteConfiguration.config || {};
    return interfaceSettings.minesiteId;
};

/**
 * Gets mine site display name
 * @param {object} state
 * @return {object} minesiteDisplayName
 */
export const getMinesiteDisplayName = (state) => {
    const interfaceSettings =
        state[REDUCER_KEY].publicSiteConfiguration.config || {};
    return interfaceSettings.minesiteDisplayName;
};

export const getComponentConfig = (state, componentName) => {
    const publicConfig =
        state && state[REDUCER_KEY].publicSiteConfiguration.config;
    return (
        (publicConfig &&
            publicConfig.component &&
            publicConfig.component[componentName]) ||
        {}
    );
};

export const getHeatMapConfig = (state, payload) => {
    const { configKeyName, pageKeyName } = payload;
    const publicConfig =
        state && state[REDUCER_KEY].publicSiteConfiguration.config;
    return publicConfig.pages.HeatMapTool[pageKeyName][configKeyName] || {};
};

export const getTimezoneAtMinesite = (state) => {
    const interfaceSettings =
        state[REDUCER_KEY].publicSiteConfiguration.config || {};
    return interfaceSettings.timezoneAtMinesite;
};

export const getTopLevelWhereOnMinesiteIdDepth = (state) => {
    const interfaceSettings =
        state[REDUCER_KEY].publicSiteConfiguration.config || {};
    return interfaceSettings.topLevelWhereOnMinesiteIdDepth;
};

export const getFilteringConfig = (state) => {
    const privateConfig =
        state[REDUCER_KEY].privateSiteConfiguration.config || {};
    return {
        filterOptions: privateConfig.filtering,
    };
};

export const getShiftConfiguration = (state) => {
    const privateConfig =
        state[REDUCER_KEY].privateSiteConfiguration.config || {};
    const publicConfig =
        state[REDUCER_KEY].publicSiteConfiguration.config || {};
    return {
        timezoneAtMinesite: publicConfig.timezoneAtMinesite,
        primaryShiftPatterns: privateConfig.primaryShiftPatterns,
    };
};

/**
 * Gets the Google Analytics site tracking id
 * @param {object} state
 * @return {object}
 */
export const getGATrackingId = (state) => {
    const publicConfig =
        state[REDUCER_KEY].publicSiteConfiguration.config || {};
    return (
        (publicConfig.analytics &&
            publicConfig.analytics.google &&
            publicConfig.analytics.google['tracking-id']) ||
        {}
    );
};

export const getNavigationLinks = (state) => {
    const links = getNavbarTabs(state);
    const overrides = getComponentConfig(state, 'LayoutDefault');

    // Merge the links with any minesite specific overrides
    // e.g. boddingtons "Operator Efficiency" -> "Haul Efficiency"
    return links.map((link) => {
        // If an override exists...
        const override =
            overrides && overrides.links && overrides.links[link.featureId];
        if (override) {
            return merge({}, link, override);
        }
        return link;
    });
};

// Converts the page featureId to the page title.
// takes into account different minesite names for pages.
export const getPageTitle = (state, featureId) => {
    const pageLinks = getNavigationLinks(state);
    const pageLink = pageLinks.find((page) => page.featureId === featureId);
    return pageLink ? pageLink.label : undefined;
};

/**
 * Get the sites PDF configuration
 * @param state
 * @return {object}
 */
export const getPDFSiteConfig = (state) => {
    const interfaceSettings =
        state[REDUCER_KEY].publicSiteConfiguration.config || {};
    return interfaceSettings.printingApi || {};
};

export const getSiteUnderConstructionInfo = (state) => {
    const publicConfig =
        state[REDUCER_KEY].publicSiteConfiguration.config || {};
    return publicConfig.siteUnderConstruction || false;
};

/**
 * NOTE: Temporary until users have full access to our knownledge base
 * Provides the unsupported wiki content id
 * @param state
 * @return {*|{}}
 */
export const getUnsupportedFeatureWiki = (state) => {
    const interfaceSettings =
        state[REDUCER_KEY].publicSiteConfiguration.config || {};
    return interfaceSettings.UnsupportedFeatureWiki || {};
};

export const getOperatorDisplayNameType = (state) => {
    return state[REDUCER_KEY].publicSiteConfiguration.config
        .operatorNameDisplayType.web;
};

/**
 * Gets all the help page configs
 * @param state
 * @param pathname
 * @return {*|{}}
 */
export const getHelpPageConfig = (state, path) => {
    const interfaceSettings =
        state[REDUCER_KEY].publicSiteConfiguration.config || {};

    const hp = interfaceSettings.helpPages;
    // check whether path matchs the regEx in helpPage config
    let config = null;
    for (let keys of Object.keys(hp)) {
        const match = new RegExp(`${keys.replace('/', '')}`);
        if (match.test(path)) {
            config = hp[keys];
            break;
        }
    }
    return config;
};

export const getIfUserIsMaxmineInternal = (state) => {
    return state[REDUCER_KEY].userInformation.current_user.IsMaxmineUser;
};
