import { createSelector } from 'reselect';
import { operator } from '@rs/core/application';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { objectToArray } from '@rs/core/utils/dataUtils';
import { getLoaderOperatorLogs } from './loaderOperatorLogs';
import {
    getFilterOptionsFromArray,
    getMissingFilterResults,
    operatorOptionsToReactSelectFormat,
} from '../../Utils/Filters/filters';
import {
    filterOptionsToReactSelectFormat,
    filterShiftOptionsToReactSelectFormat,
} from '../../Utils/Filters/filterOptionsToReactSelectFormat';
import { SELECT_STRING_DELIMITER } from '../../Components/Select';
import { API_ARRAY_DELIMITER } from '../../Api';
import { getOperatorDisplayNameType } from '../../App/Selectors';
import { APIParams } from '../types';

export const getFilters = (state: any) => state.LoaderLeague.filters;

export const getFilterOptions = createSelector(
    getFilters,
    getLoaderOperatorLogs,
    getOperatorDisplayNameType,
    (
        filters: any,
        loaderOperatorLogsByOperatorSiteId: any,
        operatorDisplayNameType: string,
    ) => {
        const loaderOperatorLogs = objectToArray(
            loaderOperatorLogsByOperatorSiteId,
        ).reduce((collection, next) => [...collection, ...next], []);

        const shiftSelector = (d: any) =>
            shiftUtils.shiftIdToShortName(d.ShiftId);
        const loaderSelector = (d: any) => d.LoadUnitSiteName;
        const operatorSelector = (d: any) => d.OperatorDisplayName;

        const operatorOptions = getFilterOptionsFromArray(
            loaderOperatorLogs,
            [
                [shiftSelector, filters.Shift],
                [loaderSelector, filters.Loader],
            ],
            operatorSelector,
        );
        const shiftOptions = getFilterOptionsFromArray(
            loaderOperatorLogs,
            [
                [operatorSelector, filters.Operator],
                [loaderSelector, filters.Loader],
            ],
            shiftSelector,
        );
        const loaderOptions = getFilterOptionsFromArray(
            loaderOperatorLogs,
            [
                [shiftSelector, filters.Shift],
                [operatorSelector, filters.Operator],
            ],
            loaderSelector,
        );

        const shifts = shiftUtils.getAllShiftShortNamesForDate(filters.Date);

        if (
            !(
                operatorDisplayNameType === 'All' ||
                operatorDisplayNameType === 'Blurred' ||
                operatorDisplayNameType === 'Name' ||
                operatorDisplayNameType === 'Id'
            )
        ) {
            throw new Error('unknown operatorDisplayNameType');
        }
        return {
            operators: operatorOptionsToReactSelectFormat(
                getMissingFilterResults(filters.Operator, operatorOptions),
                operator.displayName.OperatorDisplayFormats[
                    operatorDisplayNameType
                ],
            ),
            shifts: filterShiftOptionsToReactSelectFormat(
                shifts.rawShiftsInDay,
                getMissingFilterResults(filters.Shift, shiftOptions),
            ),
            loaders: filterOptionsToReactSelectFormat(
                getMissingFilterResults(filters.Loader, loaderOptions),
            ),
        };
    },
);

export const getMapFiltersToAPIParams = createSelector(
    getFilters,
    (filters) => {
        const params = {} as APIParams;
        params.ShiftIdDayAndNight = shiftUtils
            .generateShiftIdRange(filters.Date, filters.EndDate)
            .join(API_ARRAY_DELIMITER);

        const shiftIds = shiftUtils.getAllFirstLastFromArray(
            shiftUtils.generateShiftIdRange(
                filters.Date,
                filters.EndDate,
                filters.Shift.split(SELECT_STRING_DELIMITER),
            ),
        );

        params.latestShiftId = shiftIds.last;
        params.ShiftId = shiftIds.all.join(API_ARRAY_DELIMITER);

        if (filters.Operator.length > 0) {
            const operators = operator.operatorNamesToUniqueId(
                filters.Operator.split(SELECT_STRING_DELIMITER),
            );
            params.OperatorSiteId = operators.join(SELECT_STRING_DELIMITER);
        }
        if (filters.Loader.length > 0) {
            params.LoadUnitSiteName = filters.Loader;
        }
        return params;
    },
);
